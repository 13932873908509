<kendo-dialog *ngIf="showConfirmationDialog" title="Confirmare" [width]="450" (close)="closeConfirmationDialog()">
    <h5> {{ getFormConclusion() }} </h5>
    <div class="col-24 d-flex justify-content-around mt-4">
        <button class="btn btn-primary waves-effect waves-light" type="submit" name="action" (click)="saveMicroProfitForm()">
            <i class="mdi-content-send mr-2"></i>
            <span i18n="@@Actions$Save"> Trimite</span>
        </button>
        <button class="btn waves-effect waves-light" type="button" name="action" (click)="cancelMicroProfitForm()">
            <i class="mdi-content-clear mr-2"></i>
            <span i18n="@@Actions$Cancel">Renunță</span>
        </button>
    </div>
</kendo-dialog>


<section *ngIf="formModel && hasViewRight && !isLoading" class="d-flex flex-column mp2025-p2m-form">
    <form #mp2024Form="ngForm" class="d-flex flex-wrap">
        <div class="col-24 px-3 d-flex align-items-start">
			<div class="col px-0">
				<div class="row align-items-start">
					<div class="col d-flex">
						<div class="row align-items-center">
							<h3 class="col-auto my-0" i18n="@@MicroPofitForm$ListTitle"> {{formModel.formData.display}} </h3>
                            <h5 class="col-24 mt-2">{{formModel.formData.description}}</h5>
                            <h5 class="col-24 mt-2">Completați cu datele corespunzătoare firmei dumneavoastră.</h5>
                            <h5 class="col-24 mt-2 pink-text text-accent-2 font-weight-bold">{{formModel.formData.companyName}}</h5>
                            <label *ngIf="formModel.formData.status == 'Submitted'" class="col-24 mt-2 font-size-1-1 pink-text text-accent-2">
                                Răspunsurile au fost trimise.
                            </label>
						</div>
					</div>		
                    <i *ngIf="isDialog" class="col-auto mdi-content-clear cursor-pointer font-size-1-5" title="Închide" (click)="this.close.emit()"></i>
				</div>
			</div>
		</div>
		<div class="divider col-24 p-0 mt-2 mb-4"></div>

        <!-- Intrebari -->
        <div class="d-flex flex-wrap mb-2">
            <div class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <h5 class="col-23">
                        Doriți ca firma dumneavoastră, în cazul în care îndeplinește toate condițiile, să treacă începand cu 
                        {{ formModel.formDataParameters.nextYear | date: 'dd.MM.yyyy' }}
                        de la Impozit pe Profit la Microîntreprindere - plătitoare de impozit pe venit?
                    </h5>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesDoresteMicro" [value]="true" #yesDoresteMicro size="large" kendoRadioButton
                            [(ngModel)]="formModel.data.doresteMicro" [disabled]="isReadOnly"
                            (change)="onDoresteMicroChange()"/>
                        <label class="k-radio-label pr-3" [for]="yesDoresteMicro">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noDoresteMicro" [value]="false" #noDoresteMicro size="large" kendoRadioButton
                            [(ngModel)]="formModel.data.doresteMicro" [disabled]="isReadOnly"
                            (change)="onDoresteMicroChange()"/>
                        <label class="k-radio-label" [for]="noDoresteMicro">nu</label>
                    </div>
                </div>
            </div>

            <div class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <div class="col-23">
                        <h5 class="col-24 px-0" [ngClass]="{'disabled-header': !isReadOnly && are1FTEDisabled}">
                            La data de {{formModel.formDataParameters.lastDayOfMonth | date: 'dd.MM.yyyy'}}, 
                            aveți salariați care au norma însumată de cel puțin 8 ore pe zi sau contracte de mandat cu minim salariul minim pe economie?
                        </h5>
                        <div class="col-24 px-0" [ngClass]="{'disabled-info': !isReadOnly && are1FTEDisabled}">
                            Răspundeți DA la această întrebare dacă la {{formModel.formDataParameters.lastDayOfMonth | date: 'dd MMMM'}} sunteți în primele 30 de zile de la:<br/>
                            <ol>
                                <li><strong>înființare</strong> și intenționați să angajați înainte de expirarea termenului de 30 de zile</li>
                                <li><strong>încetarea contractui de munca sau ultimului contract de mandat</strong> 
                                    al firmei si intenționați să angajați pe altcineva sau sa faceti un nou contract de mandat înainte de expirarea termenului de 30 de zile</li>
                            </ol>
                        </div>
                    </div>
                    <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1" title="Ajutor" (click)="onAre1FTEHelp()"></i>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesAre1FTE" [value]="true" #yesAre1FTE size="large" kendoRadioButton
                            [(ngModel)]="formModel.data.are1FTE" [disabled]="isReadOnly || are1FTEDisabled"
                            (change)="onAre1FTEChange()"/>
                        <label class="k-radio-label pr-3" [for]="yesAre1FTE">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noAre1FTE" [value]="false" #noAre1FTE size="large" kendoRadioButton
                            [(ngModel)]="formModel.data.are1FTE" [disabled]="isReadOnly || are1FTEDisabled"
                            (change)="onAre1FTEChange()"/>
                        <label class="k-radio-label" [for]="noAre1FTE">nu</label>
                    </div>
                </div>
            </div>

            <div class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <h5 class="col-23" [ngClass]="{'disabled-header': !isReadOnly && caSub500kDisabled}">
                        În perioada ianuarie - {{ formModel.formDataParameters.lastDayOfMonth | date: 'MMMM' }} {{ formModel.formDataParameters.lastDayOfMonth | date: 'yyyy' }}, 
                        cifra de afaceri însumată de toate întreprinderile legate din care face parte această firmă, 
                        a fost mai mică de {{ this.formModel.formDataParameters.plafon | number : "1.0-0" }} Euro?
                    </h5>
                    <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1" title="Ajutor" (click)="onCaSub500k()"></i>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesCaSub500k" [value]="true" #yesCaSub500k size="large" kendoRadioButton
                            [(ngModel)]="formModel.data.caSub500k" [disabled]="isReadOnly || caSub500kDisabled"
                            (change)="onCaSub500kChange()"/>
                        <label class="k-radio-label pr-3" [for]="yesCaSub500k">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noCaSub500k" [value]="false" #noCaSub500k size="large" kendoRadioButton
                            [(ngModel)]="formModel.data.caSub500k" [disabled]="isReadOnly || caSub500kDisabled"
                            (change)="onCaSub500kChange()"/>
                        <label class="k-radio-label" [for]="noCaSub500k">nu</label>
                    </div>
                </div>
            </div>

            <div class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <h5 class="col-23" [ngClass]="{'disabled-header': !isReadOnly && are253MicroDisabled}">
                        Există cel puțin un acționar care deține (direct sau indirect) mai mult de 25% din capitalul social al acestei firme și care mai deține (direct sau indirect) aceeași procent și la minim o altă microîntreprindere?
                    </h5>
                    <i class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1" title="Ajutor" (click)="onAre253Micro()"></i>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesAre253Micro" [value]="true" #yesAre253Micro size="large" kendoRadioButton
                            [(ngModel)]="formModel.data.are253Micro" [disabled]="isReadOnly || are253MicroDisabled"
                            (change)="onAre253MicroChange()"/>
                        <label class="k-radio-label pr-3" [for]="yesAre253Micro">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noAre253Micro" [value]="false" #noAre253Micro size="large" kendoRadioButton
                            [(ngModel)]="formModel.data.are253Micro" [disabled]="isReadOnly || are253MicroDisabled"
                            (change)="onAre253MicroChange()"/>
                        <label class="k-radio-label" [for]="noAre253Micro">nu</label>
                    </div>
                </div>
            </div>

            <div class="col-24 px-0 m-0 d-flex flex-wrap mb-2">
                <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                    <h5 class="col-23" [ngClass]="{'disabled-header': !isReadOnly && pastreazaFirmaMicroDisabled}">
                        Doriți ca această firmă să devină microîntreprindere? Atenție: numărul de microîntreprinderi în care dețineți, direct sau indirect, 
                        mai mult de 25% din capitalul social poate fi MAXIM 1.
                    </h5>
                </div>
                <div class="col-4 px-0 d-flex align-items-center k-radio-list">
                    <div class="k-radio-item">
                        <input type="radio" name="yesPastreazaFirmaMicro" [value]="true" #yesPastreazaFirmaMicro size="large" kendoRadioButton
                            [(ngModel)]="formModel.data.pastreazaFirmaMicro" [disabled]="isReadOnly || pastreazaFirmaMicroDisabled"
                            (change)="onPastreazaFirmaMicroChange()"/>
                        <label class="k-radio-label pr-3" [for]="yesPastreazaFirmaMicro">da</label>	
                    </div>
                    <div class="k-radio-item">
                        <input type="radio" name="noPastreazaFirmaMicro" [value]="false" #noPastreazaFirmaMicro size="large" kendoRadioButton
                            [(ngModel)]="formModel.data.pastreazaFirmaMicro" [disabled]="isReadOnly || pastreazaFirmaMicroDisabled"
                            (change)="onPastreazaFirmaMicroChange()"/>
                        <label class="k-radio-label" [for]="noPastreazaFirmaMicro">nu</label>
                    </div>
                </div>
            </div>
        </div>

        <!-- Concluzie -->
        <div *ngIf="getFormConclusion() != null" class="col-24 px-0 mb-3 d-flex flex-wrap conclusion-card">
            <div class="col-24 px-0 d-flex flex-wrap align-items-center">
                <h3 class="col-23 my-3"> {{ getFormConclusion() }} </h3>
                <i *ngIf="formModel.data.ramaneMicro || formModel.data.devineMicro" class="mdi-action-help cyan-text cursor-pointer font-size-1-5 ml-2 px-1" title="Ajutor" (click)="onRamaneMicroHelp()"></i>
            </div>
        </div>

        <div class="col-24 d-flex justify-content-around mt-2">
            <button *ngIf="!isReadOnly" type="submit" class="col-auto btn waves-effect waves-light mx-1" [disabled]="saveDisabled"
                (click)="openConfirmationDialog()">
                <i class="mdi-content-send mr-2"></i>
                <span i18n="@@Actions$Save">Trimite</span>
            </button>
        </div>
    </form>
</section>

<section *ngIf="!hasViewRight && !isLoading">
    <div class="d-flex flex-wrap">
        <div class="col-24 px-3 d-flex align-items-start">
			<div class="col px-0">
				<div class="row align-items-center">
					<div class="col d-flex">
						<div *ngIf="formModel" class="row align-items-center">
							<h3 class="col-auto my-0" i18n="@@MicroPofitForm$ListTitle"> {{formModel.formData.display}} </h3>
                            <h5 class="col-24 mt-2"> {{formModel.formData.description}} </h5>
						</div>
					</div>
                    <i *ngIf="isDialog" class="col-auto mdi-content-clear cursor-pointer font-size-1-5 mt-1 mb-1" title="Închide" (click)="this.close.emit()"></i>
                </div>
            </div>
        </div>
		<div class="divider col-24 p-0 mt-2 mb-4"></div>

        <div class="d-flex flex-wrap">
            <h5>Nu aveți drepturi de vizualizare.</h5>
        </div>
    </div>
</section>