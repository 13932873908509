import { Component, EventEmitter, ElementRef, Input, Output, AfterViewInit, ViewChild, ContentChild, Injector, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ComboBoxComponent, ItemTemplateDirective, PopupSettings, VirtualizationSettings } from '@progress/kendo-angular-dropdowns';

import { LabeledControl } from '../../labeledcontrol';
import { orderBy } from '@progress/kendo-data-query';

export const COMBOBOX_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KeeComboBoxComponent),
	multi: true
};

@Component({
	selector: 'kee-combobox',
	templateUrl: 'combobox.component.html',
	providers: [COMBOBOX_VALUE_ACCESSOR],
})
export class KeeComboBoxComponent extends LabeledControl<any> implements AfterViewInit {
	private _template: ItemTemplateDirective;
	@ContentChild(ItemTemplateDirective) get template(): ItemTemplateDirective {
		return this._template;
	}
	set template(value: ItemTemplateDirective) {
		this._template = value
		if (this._comboBox) {
			this._comboBox.template = this._template;
		}
	}

	private _comboBox: ComboBoxComponent;
	@ViewChild('comboBox') get comboBox(): ComboBoxComponent {
		return this._comboBox;
	}
	set comboBox(value: ComboBoxComponent) {
		this._comboBox = value;
		if (this._comboBox) {
			this._comboBox.template = this.template;
		}
	}

	private _data: any;
	@Input()
	get data(): any {
		return this._data;
	}
	set data(value: any) {
		this._data = value;
		if (this._data != undefined) {
			this.sortedData = this._data;

			if (Array.isArray(this._data)) {
				if (this.comboBox && this.comboBox.searchbar.value) {
					if (this._data.some((d: any) => d.hasOwnProperty('isLocal'))) {
						let isLocalData = this.sortData(this._data.filter((d: any) => d.isLocal));
						let isNotLocalData = this.sortData(this._data.filter((d: any) => !d.isLocal));
						this.sortedData = isLocalData.concat(isNotLocalData);
					}
					else {
						this.sortedData = this.sortData(this._data);
					}
				}
			}
		}
	}

	@Input() public textField: string;
	@Input() public valueField: string;
	@Input() public valuePrimitive: boolean;

	@Input() public popupSettings: PopupSettings = { height: 200, animate: true };

	@Output() public filterChange = new EventEmitter<any>();

	@Input() public filterable: boolean;

	@Output() public selectionChange = new EventEmitter<any>();


	@Input() public suggest: boolean = false;
	@Input() public allowCustom: boolean = false;
	@Input() public valueNormalizer: (text: Observable<string>) => Observable<any> = (text) => text.pipe(map((userInput) => userInput));
	@Input() public loading: boolean = false;
	@Input() public showClearButton: boolean = false;
	@Input() public virtual: boolean | VirtualizationSettings;

	@Output() public open = new EventEmitter<any>();
	@Output() public close = new EventEmitter<any>();
	@Output() public keydown = new EventEmitter<any>();

	public sortedData: any;

	public constructor(injector: Injector, public element: ElementRef) {
		super(injector);
	}

	public ngAfterViewInit() {
		let self = this;
		const searchbar: any = this.comboBox.searchbar;
		const handleKeydown: any = searchbar.handleKeydown;
		searchbar.handleKeydown = function (event: any) {
			self.keydown.emit(event);
			if (!event.defaultPrevented) {
				handleKeydown.apply(searchbar, arguments);
			}
		}
	}

	public toggle(open: boolean) {
		this.comboBox.toggle(open);
	}

	public clearValue() {
		this.value = null;
	}

	public sortData(data: any): any{
		let sortedData = data;

		if (this.textField != undefined) {
			sortedData.sort((a: any, b: any) => {
				let searchValue = this.comboBox.searchbar.value.toLowerCase(),
					aStartsWith = a[this.textField].toLowerCase().indexOf(searchValue),
					bStartsWith = b[this.textField].toLowerCase().indexOf(searchValue);
				
				if (a[this.textField] == "Vezi mai multe" || b[this.textField] == "Vezi mai multe") {
					return 1;
				}

				if (aStartsWith == bStartsWith) {
					return (a[this.textField].toLowerCase()).localeCompare(b[this.textField].toLowerCase());
				}

				return aStartsWith < bStartsWith ? -1 : 1;
			});
		}

		return sortedData;
	}
}